<template lang="html">
  <v-form ref="form" lazy-validation autocomplete="off">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-autocomplete
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :label="`${$tc('label.tipo_verba', 1)}  *`"
            v-model="ajusteVerba.tipoVerba"
            :items="tiposVerba"
            item-value="id"
            item-text="nome"
            return-object
            clearable
            :disabled="somenteLeitura"
            :search-input.sync="tipoVerbaInput"
            @input="tipoVerbaAlterado"
            required
            :rules="[rules.required]"
            @click.native="buscarTiposVerba()">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-autocomplete
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :label="`${$tc('label.orcamento', 1)}  *`"
            v-model="ajusteVerba.orcamento"
            :items="orcamentos"
            item-value="id"
            item-text="descricao"
            return-object
            clearable
            :search-input.sync="orcamentoInput"
            :disabled="!ajusteVerba.tipoVerba || somenteLeitura"
            @input="orcamentoAlterado"
            required
            :rules="[rules.required]"
            @click.native="buscarOrcamentos()">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-autocomplete
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :label="`${$tc('label.periodo_orcamentario', 1)} *`"
            v-model="ajusteVerba.periodoOrcamentario"
            :items="periodosOrcamentarios"
            item-value="id"
            item-text="descricao"
            return-object
            clearable
            :search-input.sync="periodoOrcamentarioInput"
            :disabled="!ajusteVerba.orcamento || somenteLeitura"
            required
            :rules="[rules.required]"
            @click.native="buscarPeriodosOrcamentarios()"
            @input="periodoOrcamentarioAlterado">
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="ajusteVerba.periodoOrcamentario">
        <v-col cols="12" sm="6" md="4">
          <input-money
            :label="`${$t('label.valor_planejado')}`"
            :disabled="true"
            v-model="ajusteVerba.periodoOrcamentario.planejado">
          </input-money>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <input-money
            :label="`${$t('label.saldo_disponivel')}`"
            :disabled="true"
            v-model="ajusteVerba.periodoOrcamentario.saldo">
          </input-money>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            id="status"
            name="status"
            :value="$tc(`status_entidade.${ajusteVerba.status.toLowerCase()}`, 1)"
            :disabled="true"
            :label="$tc('label.status', 1)"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="ajusteVerba.periodoOrcamentario">
        <v-col>
          <v-text-field
            id="justificativa"
            name="justificativa"
            v-model="ajusteVerba.justificativa"
            :label="`${$tc('label.justificativa', 1)} *`"
            :disabled="somenteLeitura"
            :rules="[rules.required]"
            :counter="150"
            maxlength="150"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="pr-0 mr-0">
        <v-col cols="12" class="mr-0 mb-4 pr-0 pb-0">
          <v-row justify="end">
            <v-btn
              @click="aplicaPeriodo"
              class="mr-2"
              color="accent"
              v-if="!somenteLeitura"
              :disabled="!ajusteVerba.periodoOrcamentario">
              {{$tc('label.aplicar_periodo', 1)}}
              <v-icon right>refresh</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import InputMoney from '../../produto/shared-components/inputs/InputMoney';

export default {
  name: 'AjusteVerbaAporteMassivoFormResumo',
  data() {
    return {
      resource: this.$api.ajusteVerbaMassivo(this.$resource),

      tiposVerba: [],
      orcamentos: [],
      periodosOrcamentarios: [],
      tipoVerbaInput: null,
      orcamentoInput: null,
      periodoOrcamentarioInput: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    edicao: {
      type: Boolean,
      default: false,
    },
    ajusteVerba: Object,
  },
  components: {
    InputMoney,
  },
  watch: {
    tipoVerbaInput(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      if (val === (this.ajusteVerba.tipoVerba || {}).nome) {
        return;
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarTiposVerba(val);
      }, 500);
    },
    orcamentoInput(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      if (val === (this.ajusteVerba.orcamento || {}).descricao) {
        return;
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarOrcamentos(val);
      }, 500);
    },
    periodoOrcamentarioInput(val) {
      if (this.timeoutTrigger) {
        window.clearTimeout(this.timeoutTrigger);
      }
      if (val === (this.ajusteVerba.periodoOrcamentario || {}).descricao) {
        return;
      }
      this.timeoutTrigger = window.setTimeout(() => {
        if (val != null) this.buscarPeriodosOrcamentarios(val);
      }, 500);
    },
  },
  methods: {
    buscarTiposVerba(filtro) {
      const params = filtro ? { filtro } : {};
      this.resource.buscarTiposVerba(params)
        .then((r) => {
          this.tiposVerba = r.data;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    tipoVerbaAlterado(tipoVerba) {
      if (this.ajusteVerba.orcamento) {
        this.ajusteVerba.orcamento = null;
      }
      if (this.somenteLeitura || !tipoVerba) return;
      this.$emit('AjusteVerbaAporteMassivoFormResumo__VerbaSelecionada', tipoVerba);
    },
    buscarOrcamentos(filtro) {
      const params = { idTipoVerba: this.ajusteVerba.tipoVerba.id };
      if (filtro) {
        params.filtro = filtro;
      }

      this.resource.buscarOrcamentos(params)
        .then((res) => {
          this.orcamentos = res.data;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    orcamentoAlterado() {
      if (this.ajusteVerba.periodoOrcamentario) {
        this.ajusteVerba.periodoOrcamentario = null;
      }
    },
    buscarPeriodosOrcamentarios(filtro) {
      const params = {
        idTipoVerba: this.ajusteVerba.tipoVerba.id,
        idOrcamento: this.ajusteVerba.orcamento.id,
      };
      if (filtro) {
        params.filtro = filtro;
      }

      this.resource.buscarPeriodosOrcamentarios(params)
        .then((response) => {
          this.periodosOrcamentarios = response.data;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    periodoOrcamentarioAlterado() {
      const params = {
        idTipoVerba: this.ajusteVerba.tipoVerba.id,
        idOrcamento: this.ajusteVerba.orcamento.id,
        idPeriodo: this.ajusteVerba.periodoOrcamentario.id,
      };
      this.resource.buscarPlanejadoSaldoPeriodo(params)
        .then((response) => {
          this.ajusteVerba.periodoOrcamentario.planejado = response.data.planejado;
          this.ajusteVerba.periodoOrcamentario.saldo = response.data.saldo;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    aplicaPeriodo() {
      if (!this.valida()) return;
      this.$emit('AjusteVerbaAporteMassivoFormResumo__AplicaPeriodo', this.ajusteVerba);
    },
    valida() {
      return this.$refs.form.validate();
    },
    carregaDetalhamento() {
      this.tiposVerba = [this.ajusteVerba.tipoVerba];
      this.orcamentos = [this.ajusteVerba.orcamento];
      this.periodosOrcamentarios = [this.ajusteVerba.periodoOrcamentario];
      this.periodoOrcamentarioAlterado();
    },
  },
  mounted() {
    if (this.edicao) {
      this.carregaDetalhamento();
    }
  },
};
</script>
